.section-3 {
  margin-top: 65px;
  margin-bottom: 80px;
  @include media-breakpoint-down(md) {
    margin-top: 20px;
    margin-bottom: 65px;
  }
  &::before {
    display: none;
  }
  &__merits {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-top: 20px;
    }
    &--item {
      width: 290px;
      max-width: calc(100% / 3 - 10px);
      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: 35px;
        }
      }
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      .text-1 {
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.3;
        margin-top: 7px;
      }

      .text-caution {
        font-size: 1.2rem;
      }
    }
  }
}
