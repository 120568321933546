.reserve-container {
  width: 90%;
  max-width: 960px;
  margin-top: 40px;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
  padding: 24px;
  background-color: $white;
  position: relative;

  @include media-breakpoint-down(xl) {
    padding: 24px;
  }

  .title-wrapper {
    width: 100%;
    height: auto;
    position: relative;

    &::after {
      content: "";
      width: calc(100% - 60px);
      height: 1px;
      background-color: $color-black;
      display: block;
      position: absolute;
      top: 50%;
      left: 60px;
      z-index: 1;
      transform: translateY(-50%);
    }
  }

  .title {
    color: $color-black;
    background-color: $white;
    font-size: 24px;
    font-family: $font-notoSan;
    font-weight: 600;
    padding-right: 16px;
    line-height: 1;
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  // .reserve-line {
  //     margin-bottom: 40px;
  // }

  // .reserve-line,
  // .reserve-calendar {
  //     width: 100%;
  //     height: auto;
  //     margin-top: 16px;

  //     @include media-breakpoint-down(md){
  //         margin-top: 8px;
  //     }
  // }

  .description {
    color: $color-black;
    line-height: 1.6;
    letter-spacing: 0;
    margin-top: 16px;
    text-align: justify;
    font-weight: 500;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      line-height: 1.8;
      font-size: 1.8rem;
    }
  }
}

.reserve-course {
  &__title {
    color: black;
    font-size: 2.8rem;
    font-weight: bold;
    padding-left: 8px;
    border-left: solid #aa8c3c 6px;
    margin: 24px 0 16px;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
      line-height: 1.35;
    }
  }

  &__link {
    color: white;
    transition: all 300ms;

    &:hover {
      opacity: 0.8;
      transition: all 300ms;
    }

    &--color {
      color: #aa8c3c;
    }
  }

  &__button {
    background-color: #aa8c3c;
    border: solid 2px #aa8c3c;
    border-radius: 3px;
    margin-bottom: 8px;
    text-align: center;
    padding: 13px 0;
    font-size: 2rem;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      padding: 10px 0;
    }

    &--status {
      background-color: white;
      margin-bottom: 0;
    }
  }
}
