.case-study {
  width: 90%;
  max-width: 960px;
  height: auto;
  margin: {
    right: auto;
    bottom: 80px;
    left: auto;
  }
  overflow-x: hidden;

  @include media-breakpoint-down(xl) {
    margin-bottom: 40px;
  }
}

.slider-wrapper {
  margin-top: 40px;
}

.slider {
  width: 100%;
  display: flex;

  @include response(699) {
    flex-direction: column;
  }

  &__img {
    width: 43%;
    height: fit-content;
    max-width: 400px;
    line-height: 1;

    @include response(1065) {
      width: 45%;
      max-width: unset;
    }

    @include response(960) {
      width: 60%;
    }

    @include response(699) {
      width: 100%;
    }

    .img-wrapper {
      width: 100%;
      height: auto;
      position: relative;

      img {
        width: 100%;
      }

      &::after {
        content: "";
        width: 180px;
        height: 180px;
        background-color: $color-black;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        transform: translateX(50%) translateY(50%);
      }
    }

    .controller {
      margin-top: 16px;
      line-height: 0.8;
      display: flex;

      @include media-breakpoint-down(md) {
        margin-top: 8px;
      }

      .btn {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        border-radius: 0;
        border: none;
        background-color: $white;
        color: $black;
        font-family: "Roboto", sans-serif;
        font-weight: 100;
        font-size: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        @include response(699) {
          width: 40px;
          height: 40px;
          font-size: 20px;
          margin-right: 8px;
        }

        &.active {
          background-color: $color-yellow-dark;
          color: $white;
        }
      }
    }
  }

  &__description {
    width: 57.5%;
    max-width: 540px;
    height: auto;
    margin-left: auto;
    padding: {
      top: 40px;
    }

    @include response(1065) {
      width: 100%;
      padding-left: 20px;
      max-width: unset;
      margin-left: unset;
    }

    @include response(960) {
      width: 55%;
      padding-top: 20px;
    }

    @include response(699) {
      width: 100%;
      padding-top: 20px;
      padding-left: 0;
    }

    .text1 {
      font-size: 24px;
      color: $white;
      font-weight: 700;
      letter-spacing: 0.08em;
      position: relative;
      z-index: 1;
      line-height: 1.4;
      word-break: break-all;

      @include response(1065) {
        font-size: 20px;
      }

      @include response(699) {
        font-size: 24px;
      }

      .eng {
        font-family: "Avenir", sans-serif;
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.1em;
        margin-right: 8px;

        @include response(1065) {
          font-size: 16px;
        }

        @include response(699) {
          font-size: 2.4rem;
        }
      }
    }

    .trapezoid {
      width: calc(100% + 20px);
      height: 32px;
      position: relative;
      z-index: 0;
      margin-top: -14px;
      margin-left: -20px;
      margin-bottom: 20px;
      background-color: $color-yellow-dark;
      clip-path: polygon(0 0, 98% 0, 100% 100%, 0 100%);

      @include response(1065) {
        margin-top: -10px;
        height: 24px;
      }

      @include response(699) {
        width: calc(100% + 5%);
        height: 16px;
        margin-left: -6%;
      }
    }

    .text2 {
      font-size: 22px;
      letter-spacing: 0.1em;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 16px;

      @include response(699) {
        margin-bottom: 8px;
        font-size: 20px;
      }
    }

    .text3 {
      font-size: 16px;
      line-height: 1.8;
      text-align: justify;
    }
  }
}
