@font-face {
  font-family: "Avenir-black";
  src: url("../assets/fonts/AvenirLTStd-Black.otf") format("OpenType");
}

@font-face {
  font-family: 'Avenir-roman';
  src: url("../assets/fonts/AvenirLTStd-Roman.otf") format("OpenType");
}

@font-face {
  font-family: 'Avenir-book';
  src: url("../assets/fonts/AvenirLTStd-Book.otf") format("OpenType");
}

$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
//
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;


// メイクラインフォント
$font-normal: 1.8rem;

// Font family

$font-notoSan: 'Noto Sans JP', sans-serif;
$font-gothic: "Hiragino Kaku Gothic ProN",'源ノ角ゴシック JP', "メイリオ",'Meiryo', sans-serif;
$font-Montserrat: 'Montserrat', sans-serif;
$font-roboto:  'Roboto', sans-serif;
$font-avenir-black: 'Avenir-black', sans-serif;
$font-avenir-roman: 'Avenir-roman', sans-serif;
$font-avenir-book: 'Avenir-book', sans-serif;

$font-playfiar: 'Playfair Display', serif;