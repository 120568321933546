*,
*::after,
*::before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%; //1rem = 16px

  @include media-breakpoint-down(lg) {
    font-size: 46%;
  }

  @include media-breakpoint-down(sm) {
    font-size: 53%;
  }
}

body {
  @include NotoSanJp(300);
  font-size: $font-normal;
  color: $white;
  background-color: $color-main-bg;
  text-align: justify;
  line-height: 1;
  overflow-x: hidden;
  letter-spacing: 1px;
}

.font-28 {
  font-size: 2.8rem;
}
.font-22 {
  font-size: 2.2rem;
}

.font-bold {
  font-weight: bold;
}

.width-100 {
  width: 100% !important;
}

.container {
  width: 90%;
  max-width: #{$container};
  margin: 0 auto;
  @include media-breakpoint-down(xl) {
    width: 90%;
  }
}
.container-sp-2 {
  @include media-breakpoint-down(md) {
    width: 90%;
    margin: 0 auto;
  }
}
.english-font {
  font-family: $font-avenir-black;
}
.yellow-t {
  color: $color-yellow-dark;
}

.flex-box {
  display: flex;
}

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: $color-black;
  padding: 40px 0 40px;
  max-width: 100%;
  width: 100%;
}

.none-sp {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.none-pc {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.section {
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    width: 700px;
    height: 1130px;
    background-color: $color-black;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50%)) skew(-15deg);
  }

  &.no-trapezoid {
    &::before {
      content: "";
      display: none;
    }
  }

  &__title {
    color: $white;
    p {
      font-weight: bold;
      &:first-child {
        font-size: 42px;
        line-height: 1;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 8px;
        @include media-breakpoint-down(md) {
          margin-bottom: 7px;
        }
      }
      &:last-child {
        font-size: 24px;
        letter-spacing: 0.1em;
        @include media-breakpoint-down(md) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}

.section-title {
  color: $white;
  p {
    font-weight: bold;
    &:first-child {
      font-size: 42px;
      line-height: 1;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include media-breakpoint-down(md) {
        margin-bottom: 7px;
        font-size: 40px;
        letter-spacing: 0.1em;
      }
    }
    &:last-child {
      font-size: 24px;
      letter-spacing: 0.1em;
      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

@mixin py {
  clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
}
