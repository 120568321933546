.section-certificate {
  width: 90%;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 120px;

  @include media-breakpoint-down(md) {
    margin-bottom: 320px;
  }

  .text1 {
    font-size: 18px;
    margin-top: 24px;
    letter-spacing: 0.1em;
    line-height: 1.6;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .main-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

    @include response(799) {
      flex-direction: column;
    }
  }

  .certificate-wrapper {
    width: 32%;
    max-width: 460px;
    height: auto;

    @include response(799) {
      width: 100%;
      max-width: unset;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .description {
      width: 100%;
      background-color: $color-black;
      border-top: solid 8px $color-yellow-dark;
      padding: 8px 0 12px;

      @include media-breakpoint-down(md) {
        padding: 12px 3%;
      }

      .text2 {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.12em;
        text-align: center;
        line-height: 1;
        margin-bottom: 12px;
      }

      .text3 {
        font-weight: 700;
        font-size: 2rem;
        letter-spacing: 0.12em;
        text-align: center;
        line-height: 1;
        margin-bottom: 8px;
      }

      .text4 {
        font-size: 16px;
        text-align: center;
        line-height: 1.2;
        letter-spacing: 0.1em;
      }
    }
  }

  .certificate {
    background-color: #8b8b8b;
  }

  .img-wrapper {
    width: 50%;
    line-height: 1;
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}
