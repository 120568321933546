@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin response($screen) {
  @media (max-width: $screen+"px") {
    @content;
  }
}

@mixin min($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

@mixin max-height($screen) {
  @media (max-height: $screen+"px") {
    @content;
  }
}

@mixin min-height($screen) {
  @media (min-height: $screen+"px") {
    @content;
  }
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);
  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max==null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min==null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next);
  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max==null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min==null {
    @include media-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 16px
*/
@mixin response-by-device($breakpoint) {
  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint==tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}
@mixin container($container-width) {
  max-width: $container-width;
  @include response(phone) {
    max-width: 90%;
  }
  @include response(tab-port) {
    max-width: 90%;
  }
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }
  @include clearfix;
}
