
// red button
button.button{
    height: 80px;
}
.button {
    background-color: #E60012;   
    // border: 6px #F0ACA8 solid; 
    border: none;
    display: flex;
    position: relative;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all .1s;
    width: 100%;
    cursor: pointer;
    &:focus{
        outline: none;
    }
    &:hover{
        background-color: rgba(#E60012, .8);   
    }
    p {
        color: white;
        font-size: 24px;
        line-height: 38px;
        @include media-breakpoint-down(xl){
            font-size: 18px;
            text-align: center;
            line-height: 24px;
        }
    } 
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        @include media-breakpoint-down(xs){
            right: 15px;
        }
        @include response(320){
            right: 10px;
            width: 15px;
        }
    }     
}

.return-btn {
    background-color: $color-text-gray;
    // border-color: #b5b5b5;
    &:hover{
        background-color: rgba($color-text-gray, .8);   
    }
    img{
        right: auto;
        left: 25px;
        transform: rotate(180deg) translateY(50%);
        @include media-breakpoint-down(xs){
            left: 15px;
        }
        @include response(320){
            left: 10px;
            width: 15px;
        }
    }
}