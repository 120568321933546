// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xxs: 360,
  xs: 435px,
  sm: 475px,
  md: 521px,
  lg: 960px,
  xl: 961px,
  xxl: 1125px,
) !default;
// scss-docs-end grid-breakpoints

/* >= Tablet min-width: 740px */

/* PC medium resolution > min-width: 1113px */

/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/

/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/

$container: 960px;
$side-width: calc((100vw - #{$container}) / 2);

$side-width-sp: 5%;
