.contact-info {
  width: 90%;
  max-width: 960px;
  margin-top: 40px;
  margin-bottom: 120px;
  margin-right: auto;
  margin-left: auto;
  padding: {
    top: 40px;
    bottom: 40px;
  }
  background-color: $black;

  @include response(800) {
    padding: 40px 24px;
    margin-bottom: 80px;
  }

  .description {
    color: $white;
    text-align: center;
    letter-spacing: 0.12em;
    line-height: 1.6;
  }

  .info-wrapper {
    width: 100%;
    margin-top: 24px;
    display: flex;

    @include response(800) {
      flex-direction: column;
    }
  }

  .info-detail {
    width: 50%;
    padding: 0 24px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include response(800) {
      padding: 0;
      width: 100%;

      &:first-child {
        margin-bottom: 20px;
      }
    }

    .caption {
      margin-bottom: 8px;
      font-size: 18px;
      color: $color-yellow-dark;
      font-weight: 700;
      letter-spacing: 0.08em;
    }

    .img-wrapper {
      display: flex;
      margin-bottom: 4px;
      width: 100%;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }

    a {
      font-size: 20px;
      font-family: $font-roboto;
      font-weight: 500;
      color: #fff;
      pointer-events: none;

      @include response(800) {
        font-size: 15px;
      }

      &.tel {
        font-size: 26px;
        margin-bottom: 4px;

        @include response(800) {
          pointer-events: all;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .contact-caution {
      font-size: 12px;
      line-height: 1;
    }
  }

  .separator {
    width: 3px;
    height: 124px;
    background-color: $white;

    @include response(800) {
      display: none;
    }
  }
}
