.section-1 {
  @include media-breakpoint-down(md) {
    padding-bottom: 450px;
  }
  &::before {
    top: 297px;
    @include media-breakpoint-down(md) {
      width: 530px;
      left: 80%;
    }
  }
  &--info {
    font-size: 20px;
    font-weight: 300;
    line-height: 2.4;
    width: 574px;
    letter-spacing: 1px;
    text-align: justify;
    margin-top: 7px;
    font-feature-settings: "palt";

    @include response(630) {
      width: auto;
    }
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
  &__features {
    &--item {
      &:first-child {
        margin-top: 40px;
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      p {
        font-size: 2.4rem;
        letter-spacing: 1px;
        line-height: 1.6;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
        }

        span {
          font-size: 72px;
          margin-right: 24px;
          line-height: 9rem;
          display: inline-block;
          position: relative;
          @include media-breakpoint-down(md) {
            margin-right: 12px;
            font-size: 30px;
            line-height: 41px;
          }
          &::after {
            width: 37px;
            height: 8px;
            content: "";
            background-color: $white;
            bottom: 3px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @include media-breakpoint-down(md) {
              width: 16px;
              height: 2px;
              bottom: 2px;
            }
          }
        }
      }
    }
  }

  .annotation {
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    color: $color-yellow-dark;
    line-height: 1;
    margin-top: 16px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
      letter-spacing: 0.08em;
    }
  }

  .fv-top__second {
    margin-top: 60px;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
}
