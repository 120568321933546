.section-4 {
  margin-bottom: 80px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
  }
}

.container {
  position: relative;

  &--offline {
    margin-bottom: 56px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 0;
    }
  }

  &--online {
    margin-bottom: 56px;
    padding: 56px 0;
  }

  &--recommend {
    margin-top: 40px;
  }

  &--recommend::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: $color-yellow-dark;
    width: calc((100vw - 100%) / 2);
    height: 100%;
  }

  &--online::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    z-index: -1;
    transform: translateX(50%);
    background-color: $black;
    width: 100vw;
    height: 100%;
  }
}

.online-title {
  margin-bottom: 8px;
  color: $color-yellow-dark;
  font-size: 4rem;
  font-family: $font-avenir-roman;
  text-align: center;

  @include media-breakpoint-down(lg) {
    font-size: 3.2rem;
  }
}

.online-sub-title {
  margin-bottom: 16px;
  color: $white;
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;

  @include media-breakpoint-down(lg) {
    font-size: 1.8rem;
  }
}

.online-detail {
  margin-bottom: 40px;
  font-size: 1.8rem;
  line-height: 1.6;

  @include media-breakpoint-down(lg) {
    margin-bottom: 24px;
    font-size: 1.6rem;
  }
}

.recommend {
  position: relative;
  margin-left: auto;
  margin-bottom: 88px;
  padding: 32px 0 40px 24px;
  background-color: $color-yellow-dark;
  width: 70%;
  min-height: 180px;

  @include media-breakpoint-down(lg) {
    width: 90%;
    margin-bottom: 48px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-98.5%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    background-color: $color-yellow-dark;
    width: 36px;
    height: 100%;
  }

  &__text-1 {
    margin-bottom: 12px;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.2;

    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }
  }

  &__text-2 {
    margin-bottom: 8px;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.2;
  }

  &__text-3 {
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
      line-height: 1.2;
    }
  }

  &__campaign {
    position: relative;
    background-color: $color-yellow-dark;
    margin-bottom: 0;
    padding: 12px 0;
    text-align: center;

    @include media-breakpoint-down(xl) {
      font-size: 1.8rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-yellow-dark;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-yellow-dark;
    }
  }
}

.campaign {
  &__content {
    margin-top: 24px;
    padding: 24px;
    background-color: $black;

    & > ul {
      margin-bottom: 16px;

      & > li {
        font-size: 1.4rem;
        list-style: none;
        line-height: 1.4;
      }

      span {
        border-bottom: solid 2px $color-yellow-dark;
      }
    }

    & > ol {
      & > li {
        font-size: 1.4rem;
        list-style: none;
        line-height: 1.4;
      }
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 16px;

    & > span {
      border-bottom: solid 2px $color-yellow-dark;
    }
  }

  &__note {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.course {
  &__news {
    letter-spacing: 0.1em;
    margin-top: 40px;
    padding: 16px;
    border: solid 1px $color-yellow-dark;

    @include media-breakpoint-down(xl) {
      margin-top: 24px;
    }
  }

  &__news-title {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;

    @include media-breakpoint-down(xl) {
      margin-top: 14px;
    }

    & > span {
      display: inline-block;
      line-height: 1.2;
      border-bottom: solid 1px $color-yellow-dark;
    }
  }

  &__news-text {
    font-size: 1.6rem;
    line-height: 1.6;

    @include media-breakpoint-down(xl) {
      font-size: 1.4rem;
      line-height: 1.9;
    }

    & > span {
      display: inline-block;
      font-weight: bold;
      line-height: 1.2;
      border-bottom: solid 1px $color-yellow-dark;

      @include media-breakpoint-down(xl) {
        display: inline;
      }
    }
  }

  &__pickup {
    display: flex;
    gap: 3%;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      gap: unset;
    }
  }

  &__area {
    width: 100%;
    margin-top: 24px;
    background-color: $black;
    padding: 24px;

    &--small {
      width: 48.5%;

      @include media-breakpoint-down(xl) {
        width: 100%;
      }
    }
  }

  &__head {
    display: flex;
    gap: 8px;
    margin-bottom: 4px;
  }

  &__title {
    color: #aa8c3c;
    font-size: 1.8rem;
    font-family: "Avenir-roman", sans-serif;
    letter-spacing: 0.2em;
    line-height: 1.4;
    letter-spacing: 0.1em;
  }

  &__badge {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    color: white;
    margin-bottom: 16px;

    span {
      background-color: #aa8c3c;
      line-height: 1.4;
      padding: 4px 8px;

      @include media-breakpoint-down(md) {
        padding: 1.5px 4px 2px;
      }
    }
  }

  &__name {
    font-size: 2.4rem;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  &__item {
    display: flex;
    gap: 1.7%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__avatar {
    width: 33%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &--pickup {
      width: 100%;
    }
  }

  &__contents {
    width: 65.5%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &--pickup {
      width: 100%;
    }
  }

  &__image {
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 7px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__note {
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    line-height: 1.6;
    padding-left: 2em;

    &--margin-bottom {
      margin-bottom: 0;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  &__menu {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.6;

    @include media-breakpoint-down(md) {
      line-height: 1.5;
    }

    & > span {
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  &__border {
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 16px 0;
  }

  &__dots-border {
    height: 1px;
    margin-bottom: 16px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 0px;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__price-area {
    & > p {
      font-size: 1.6rem;
      letter-spacing: 0.08em;
      margin-bottom: 26px;
      font-weight: bold;
    }
  }

  &__reality {
    width: 100%;
    margin-bottom: 22px;
    border-bottom: solid 1px $color-yellow-dark;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0;
      background: none;
      margin-bottom: 12px;
    }
  }

  &__online {
    width: 100%;
    border-bottom: solid 1px #8b8b8b;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0;
      background: none;
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
      align-items: center;
      justify-content: space-between;
    }
  }

  &__training {
    font-size: 1.4rem;
    letter-spacing: 0.08em;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }

  &__fee {
    font-family: "Avenir", sans-serif;
    font-size: 3.2rem;
    letter-spacing: 0.08em;

    &--online {
      display: flex;
      align-items: baseline;
      gap: 0.4rem;
    }
  }

  &__annotation {
    font-size: 1.6rem;
    line-height: 1.5;
    color: #aa8c3c;
    letter-spacing: 0;
    margin-top: 16px;
  }

  &__heading {
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.06em;
    line-height: 1.5;
    text-align: center;
    margin: 40px 0 24px;
  }

  &__subtext {
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.2;

    @include media-breakpoint-down(md) {
      text-align: unset;
      line-height: 1.7;
    }
  }
}

//slider
.slick-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 12px 0 16px;

  button {
    display: none;
  }

  li {
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 100vh;
    background-color: $white;
    list-style: none;

    &.slick-active {
      background-color: $color-yellow-dark;
    }
  }
}

.slick-slide {
  margin: 0 8px;
}
