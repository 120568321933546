.section-5{
    margin-top: 120px;
    min-height: 610px;
    position: relative;

    @include media-breakpoint-down(md){
        margin-top: -300px;
        padding-top: 70px;
    }

    @include media-breakpoint-down(xl){
        min-height: 800px;
        margin-bottom: 60px;
    }

    &::before{
        display: none;
    }

    &__main{
        width: calc(100vw - calc((100vw - 960px) / 2) - 60px);
        height: auto;
        margin-top: 50px;
        padding: {
            top: 30px;
            left: 116px;
            bottom: 110px
        };
        background-color: #000;
        float: right;
        clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
        z-index: 2;
        position: relative;

        @include media-breakpoint-down(xl){
            width: 95%;
            padding-right: 5%;
            padding: {
                top: 100px;
                left: 80px;
                bottom: 40px
            };
            margin-top: -60px;
        }

        p.trainer-name {
            font-size: 18px;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        p.trainer-detail {
            width: 100%;
            max-width: 290px;
            font-size: 16px;
            text-align: justify;
            line-height: 1.6;

            @include media-breakpoint-down(xl) {
                max-width: 350px;
            }
        }
    }
    &__py{
        width: 100%;
        background-color: $color-yellow-dark;
        padding-left: 65px;
        padding-top: 25px;
        padding-bottom: 33px;
        padding-right: 40px;
        @include media-breakpoint-down(md){
            width: 95%;
            align-self: flex-end;
            padding-top: 60px;
            padding-right: 10%;

          }
        @include py;
        p{
            font-size: 18px;
            font-weight: bold;
            line-height: 30px;
            text-align: justify;
            @include media-breakpoint-down(md){
                width:100%;
                max-width: 100%;
            }
            &:last-child{
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
            }

        }
    }

    &__bottom{
        position: relative;
        @include clearfix();

        .trainer-image {
            width: 282px;
            height: 365px;
            position: relative;
            z-index: 999;
            margin-top: 50px;
            margin-left: 15%;

            &:before {
                content: '';
                width: 200px;
                height: 200px;
                background-color: $color-yellow-dark;
                display: block;
                position: absolute;
                z-index: -1;
                top: -30px;
                left: -25px;
            }

            &:after {
                content: url('../assets/images/trainer-name.webp');
                display: block;
                position: absolute;
                bottom: -50px;
                right: -10%;
            }

            @include media-breakpoint-up(xl) {
                display: none;
            }

            img {
                width: 100%;
            }
        }

        .trainer-img-wrapper {
            width: 380px;
            height: 490px;
            position: absolute;
            top: 25px;
            right: $side-width;
            z-index: 3;

            @include media-breakpoint-down(xl){
                display: none;
            }
            
            &:before {
                content: '';
                width: 200px;
                height: 200px;
                background-color: $color-yellow-dark;
                display: block;
                position: absolute;
                top: -25px;
                left: -20px;
                z-index: -1;
            }

            &:after {
                content: url('../assets/images/trainer-name.webp');
                position: absolute;
                bottom: 150px;
                left: -165px;

            }
            
            img {
                width: 100%;
            }
        }

        div.decoration {
            width: calc(100vw - calc((100vw - 960px) / 2) - 60px);
            height: 311px;
            background-color: $color-yellow-dark;
            float: right;
            clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
            z-index: 1;
            position: absolute;
            right: -111px;
            top: 100px;

            @include media-breakpoint-down(xl) {
                width: 95%;
                top: 320px;
                left: 90px;
            }
        }

        @include media-breakpoint-down(md){
            width: 100vw;
        }
    }
}
