.nav-banner {
  margin-bottom: 120px;

  @include response(800) {
    margin-bottom: 80px;
  }

  &__image {
    width: 480px;
    margin-right: auto;
    margin-left: auto;

    @include response(800) {
      width: 100%;
    }

    img{
      width: 100%;
      height: auto;
    }
  }
}
