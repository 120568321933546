.fv-top {
  margin-bottom: 40px;

  .container {
    @include media-breakpoint-down(md) {
      width: 100vw;
    }
  }

  &::before {
    max-height: calc(100vh + 310px);
    @include media-breakpoint-down(md) {
      left: 35%;
      width: 400px;
      max-height: calc(100vh + 200px);
    }
  }

  &__main {
    &__img {
      position: relative;
      display: flex;
      padding-top: 26px;
      margin-bottom: 24px;
      width: 90%;

      @include media-breakpoint-down(md) {
        width: 77%;
        margin-right: 5%;
      }

      p {
        position: absolute;
        top: 26px;
        right: -8px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        writing-mode: vertical-lr;
        letter-spacing: 0.4rem;
        transform: translateX(100%);
      }

      &--main-img {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;

        @include media-breakpoint-down(xl) {
          object-fit: cover;
          object-position: right;
        }
      }

      .msb-logo-sp {
        position: absolute;
        width: 280px;
        height: auto;
        bottom: -50px;
        right: -30px;
        transform: translateY(20%);

        @include min(961) {
          display: none;
        }
      }
    }

    &__des {
      margin-bottom: 80px;
      position: relative;

      @include media-breakpoint-down(xl) {
        margin-top: 123px;
      }

      img {
        max-width: 100%;
      }

      p {
        font-weight: bold;
        &.first {
          font-size: 28px;
          line-height: 1.6;
          letter-spacing: 0.1em;
          text-align: justify;

          @include media-breakpoint-down(md) {
            font-size: 31px;
            line-height: 1.6;
          }

          @include media-breakpoint-down(md) {
            font-size: 22px;
            line-height: 1.6;
          }
        }
        &:last-child {
          letter-spacing: 2px;
        }
      }

      .annotation {
        font-size: 12px;
        letter-spacing: 0.1em;
        color: $color-yellow-dark;
        line-height: 1;
        margin-top: 12px;
      }

      &--msb-img {
        position: absolute;
        bottom: 24px;
        right: 0;
        width: 410px;
        height: auto;

        @include media-breakpoint-down(xl) {
          display: none;
        }
      }

      .font-97 {
        font-size: 9.7rem;
      }

      .font-30 {
        font-size: 3rem;
      }

      .font-36 {
        font-size: 36px;
      }

      .font-25 {
        font-size: 2.5rem;
      }

      .font-56 {
        font-size: 56px;
      }
    }
  }

  &__news {
    width: 90%;
    max-width: 960px;
    margin: {
      right: auto;
      left: auto;
    }

    .news-link {
      display: block;
      border-bottom: dashed 1px #fff;
      &:first-child {
        border-top: solid 1px #fff;
      }
      &:last-child {
        border-bottom: solid 1px #fff;
      }
    }

    #news-wrapper {
      margin-top: 24px;

      .news-detail {
        width: 100%;
        padding: {
          top: 12px;
          bottom: 12px;
        }
        display: flex;
        align-items: center;

        &:hover .news-title {
          color: $color-yellow-dark;
        }

        &:hover .news-check-decoration::after {
          width: 0;
        }
      }

      .news-title {
        width: 720px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.08em;
        color: #fff;
        margin-right: 16px;
        transition: all 300ms;

        @include response(959) {
          width: 80%;
          margin-right: 0;
        }

        @include response(520) {
          width: 75%;
          font-size: 14px;
        }
      }

      .news-date {
        width: 95px;
        font-family: $font-avenir-book;
        font-size: 16px;
        font-weight: 100;
        line-height: 1;
        padding-top: 6px;
        margin-right: 16px;
        color: #fff;
        letter-spacing: 0.08em;

        @include response(959) {
          width: 15%;
          margin-right: 5%;
        }

        @include response(520) {
          width: 20%;
          font-size: 14px;
          margin-right: 5%;
        }
      }

      .news-check-decoration {
        width: 163px;
        font-family: $font-avenir-roman;
        padding-top: 6px;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.08em;
        position: relative;
        color: #fff;

        @include response(959) {
          display: none;
        }

        &::after {
          content: "";
          width: 60%;
          height: 2px;
          display: block;
          background-color: #fff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all 300ms;
        }
      }
    }

    .view-all-news {
      width: 280px;
      height: 56px;
      margin-top: 40px;
      background-color: $color-yellow-dark;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-notoSan;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      letter-spacing: 0.08em;
      margin-right: auto;
      margin-left: auto;
      transition: all 300ms;

      &:hover {
        opacity: 0.8;
      }
    }

    // データが見つからない場合の処理
    .no-data {
      border-top: solid 1px #fff;
      border-bottom: solid 1px #fff;
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
    }
  }

  &__second {
    margin-left: $side-width;
    height: auto;
    padding-top: 120px;

    @include clearfix();

    @include media-breakpoint-down(md) {
      padding-top: 0;
      margin-top: 80px;
    }

    &.left {
      margin-right: $side-width;
      margin-left: 0;
      .fv-top__second--logo {
        top: -147px;
        left: auto;
        right: -184px;
        @include media-breakpoint-down(xxl) {
          right: 0;
        }
        @include media-breakpoint-down(xl) {
          right: -110px;
          top: -100px;
        }
        @include media-breakpoint-down(md) {
          top: auto;
          right: -48px;
          bottom: 20px;
        }
      }
      .fv-top__second__main {
        float: left;
      }
      .fv-top__second--main {
        float: left;
      }
    }

    &__main {
      position: relative;
      float: right;
      width: auto;
      @include media-breakpoint-down(xl) {
        width: 80vw;
        height: auto;
        object-fit: cover;
      }
    }

    &--logo {
      position: absolute;
      top: -35px;
      left: -105px;
      width: 330px;
      height: auto;
      @include media-breakpoint-down(xxl) {
        left: 0vw;
      }
      @include media-breakpoint-down(xl) {
        width: 240px;
        height: auto;
      }
      @include media-breakpoint-down(md) {
        width: 132px;
        height: auto;
        top: auto;
        bottom: 20px;
        left: -40px;
      }
    }
    &--main {
      width: auto;
      height: auto;
      float: right;
      @include media-breakpoint-down(xl) {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
