html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

.grid {
  width: 100%;
  display: block;
  padding: 0;
}

.grid.wide {
  max-width: 1000px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}

.row.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.col {
  padding-left: 4px;
  padding-right: 4px;
}

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0;
}

.c-0 {
  display: none;
}

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.c-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.c-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.c-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.c-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.c-o-1 {
  margin-left: 8.33333%;
}

.c-o-2 {
  margin-left: 16.66667%;
}

.c-o-3 {
  margin-left: 25%;
}

.c-o-4 {
  margin-left: 33.33333%;
}

.c-o-5 {
  margin-left: 41.66667%;
}

.c-o-6 {
  margin-left: 50%;
}

.c-o-7 {
  margin-left: 58.33333%;
}

.c-o-8 {
  margin-left: 66.66667%;
}

.c-o-9 {
  margin-left: 75%;
}

.c-o-10 {
  margin-left: 83.33333%;
}

.c-o-11 {
  margin-left: 91.66667%;
}

/* >= Tablet */
@media (min-width: 1000px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .col {
    padding-left: 8px;
    padding-right: 8px;
  }
  .m-0 {
    display: none;
  }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block;
  }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .m-o-1 {
    margin-left: 8.33333%;
  }
  .m-o-2 {
    margin-left: 16.66667%;
  }
  .m-o-3 {
    margin-left: 25%;
  }
  .m-o-4 {
    margin-left: 33.33333%;
  }
  .m-o-5 {
    margin-left: 41.66667%;
  }
  .m-o-6 {
    margin-left: 50%;
  }
  .m-o-7 {
    margin-left: 58.33333%;
  }
  .m-o-8 {
    margin-left: 66.66667%;
  }
  .m-o-9 {
    margin-left: 75%;
  }
  .m-o-10 {
    margin-left: 83.33333%;
  }
  .m-o-11 {
    margin-left: 91.66667%;
  }
}
/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px;
  }
  .col {
    padding-left: 12px;
    padding-right: 12px;
  }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .l-0 {
    display: none;
  }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block;
  }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .l-o-1 {
    margin-left: 8.33333%;
  }
  .l-o-2 {
    margin-left: 16.66667%;
  }
  .l-o-3 {
    margin-left: 25%;
  }
  .l-o-4 {
    margin-left: 33.33333%;
  }
  .l-o-5 {
    margin-left: 41.66667%;
  }
  .l-o-6 {
    margin-left: 50%;
  }
  .l-o-7 {
    margin-left: 58.33333%;
  }
  .l-o-8 {
    margin-left: 66.66667%;
  }
  .l-o-9 {
    margin-left: 75%;
  }
  .l-o-10 {
    margin-left: 83.33333%;
  }
  .l-o-11 {
    margin-left: 91.66667%;
  }
}
/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px;
  }
}
/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px;
  }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px;
  }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px;
  }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .wide .l-0 {
    display: none;
  }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block;
  }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .wide .l-o-1 {
    margin-left: 8.33333%;
  }
  .wide .l-o-2 {
    margin-left: 16.66667%;
  }
  .wide .l-o-3 {
    margin-left: 25%;
  }
  .wide .l-o-4 {
    margin-left: 33.33333%;
  }
  .wide .l-o-5 {
    margin-left: 41.66667%;
  }
  .wide .l-o-6 {
    margin-left: 50%;
  }
  .wide .l-o-7 {
    margin-left: 58.33333%;
  }
  .wide .l-o-8 {
    margin-left: 66.66667%;
  }
  .wide .l-o-9 {
    margin-left: 75%;
  }
  .wide .l-o-10 {
    margin-left: 83.33333%;
  }
  .wide .l-o-11 {
    margin-left: 91.66667%;
  }
}
/* >= Tablet min-width: 740px */
/* PC medium resolution > min-width: 1113px */
/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/
/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/
@font-face {
  font-family: "Avenir-black";
  src: url("../assets/fonts/AvenirLTStd-Black.otf") format("OpenType");
}
@font-face {
  font-family: "Avenir-roman";
  src: url("../assets/fonts/AvenirLTStd-Roman.otf") format("OpenType");
}
@font-face {
  font-family: "Avenir-book";
  src: url("../assets/fonts/AvenirLTStd-Book.otf") format("OpenType");
}
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 16px
*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
@media (max-width: 959.98px) {
  html {
    font-size: 46%;
  }
}
@media (max-width: 474.98px) {
  html {
    font-size: 53%;
  }
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  color: #fff;
  background-color: #5C5C5C;
  text-align: justify;
  line-height: 1;
  overflow-x: hidden;
  letter-spacing: 1px;
}

.font-28 {
  font-size: 2.8rem;
}

.font-22 {
  font-size: 2.2rem;
}

.font-bold {
  font-weight: bold;
}

.width-100 {
  width: 100% !important;
}

.container {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
}
@media (max-width: 960.98px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 520.98px) {
  .container-sp-2 {
    width: 90%;
    margin: 0 auto;
  }
}

.english-font {
  font-family: "Avenir-black", sans-serif;
}

.yellow-t {
  color: #AA8C3C;
}

.flex-box {
  display: flex;
}

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 40px 0 40px;
  max-width: 100%;
  width: 100%;
}

@media (max-width: 520.98px) {
  .none-sp {
    display: none !important;
  }
}

.none-pc {
  display: none !important;
}
@media (max-width: 520.98px) {
  .none-pc {
    display: block !important;
  }
}

.section {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.section::before {
  content: "";
  position: absolute;
  width: 700px;
  height: 1130px;
  background-color: #000000;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%) skew(-15deg);
}
.section.no-trapezoid::before {
  content: "";
  display: none;
}
.section__title {
  color: #fff;
}
.section__title p {
  font-weight: bold;
}
.section__title p:first-child {
  font-size: 42px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 8px;
}
@media (max-width: 520.98px) {
  .section__title p:first-child {
    margin-bottom: 7px;
  }
}
.section__title p:last-child {
  font-size: 24px;
  letter-spacing: 0.1em;
}
@media (max-width: 520.98px) {
  .section__title p:last-child {
    font-size: 20px;
    line-height: 24px;
  }
}

.section-title {
  color: #fff;
}
.section-title p {
  font-weight: bold;
}
.section-title p:first-child {
  font-size: 42px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media (max-width: 520.98px) {
  .section-title p:first-child {
    margin-bottom: 7px;
    font-size: 40px;
    letter-spacing: 0.1em;
  }
}
.section-title p:last-child {
  font-size: 24px;
  letter-spacing: 0.1em;
}
@media (max-width: 520.98px) {
  .section-title p:last-child {
    font-size: 20px;
    line-height: 24px;
  }
}

.grid {
  width: 100%;
  display: block;
  padding: 0;
}

.grid.wide {
  max-width: 1000px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}

.row.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.col {
  padding-left: 4px;
  padding-right: 4px;
}

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0;
}

.c-0 {
  display: none;
}

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.c-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.c-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.c-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.c-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.c-o-1 {
  margin-left: 8.33333%;
}

.c-o-2 {
  margin-left: 16.66667%;
}

.c-o-3 {
  margin-left: 25%;
}

.c-o-4 {
  margin-left: 33.33333%;
}

.c-o-5 {
  margin-left: 41.66667%;
}

.c-o-6 {
  margin-left: 50%;
}

.c-o-7 {
  margin-left: 58.33333%;
}

.c-o-8 {
  margin-left: 66.66667%;
}

.c-o-9 {
  margin-left: 75%;
}

.c-o-10 {
  margin-left: 83.33333%;
}

.c-o-11 {
  margin-left: 91.66667%;
}

/* >= Tablet */
@media (min-width: 1000px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .col {
    padding-left: 8px;
    padding-right: 8px;
  }
  .m-0 {
    display: none;
  }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block;
  }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .m-o-1 {
    margin-left: 8.33333%;
  }
  .m-o-2 {
    margin-left: 16.66667%;
  }
  .m-o-3 {
    margin-left: 25%;
  }
  .m-o-4 {
    margin-left: 33.33333%;
  }
  .m-o-5 {
    margin-left: 41.66667%;
  }
  .m-o-6 {
    margin-left: 50%;
  }
  .m-o-7 {
    margin-left: 58.33333%;
  }
  .m-o-8 {
    margin-left: 66.66667%;
  }
  .m-o-9 {
    margin-left: 75%;
  }
  .m-o-10 {
    margin-left: 83.33333%;
  }
  .m-o-11 {
    margin-left: 91.66667%;
  }
}
/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px;
  }
  .col {
    padding-left: 12px;
    padding-right: 12px;
  }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .l-0 {
    display: none;
  }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block;
  }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .l-o-1 {
    margin-left: 8.33333%;
  }
  .l-o-2 {
    margin-left: 16.66667%;
  }
  .l-o-3 {
    margin-left: 25%;
  }
  .l-o-4 {
    margin-left: 33.33333%;
  }
  .l-o-5 {
    margin-left: 41.66667%;
  }
  .l-o-6 {
    margin-left: 50%;
  }
  .l-o-7 {
    margin-left: 58.33333%;
  }
  .l-o-8 {
    margin-left: 66.66667%;
  }
  .l-o-9 {
    margin-left: 75%;
  }
  .l-o-10 {
    margin-left: 83.33333%;
  }
  .l-o-11 {
    margin-left: 91.66667%;
  }
}
/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px;
  }
}
/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px;
  }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px;
  }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px;
  }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .wide .l-0 {
    display: none;
  }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block;
  }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .wide .l-o-1 {
    margin-left: 8.33333%;
  }
  .wide .l-o-2 {
    margin-left: 16.66667%;
  }
  .wide .l-o-3 {
    margin-left: 25%;
  }
  .wide .l-o-4 {
    margin-left: 33.33333%;
  }
  .wide .l-o-5 {
    margin-left: 41.66667%;
  }
  .wide .l-o-6 {
    margin-left: 50%;
  }
  .wide .l-o-7 {
    margin-left: 58.33333%;
  }
  .wide .l-o-8 {
    margin-left: 66.66667%;
  }
  .wide .l-o-9 {
    margin-left: 75%;
  }
  .wide .l-o-10 {
    margin-left: 83.33333%;
  }
  .wide .l-o-11 {
    margin-left: 91.66667%;
  }
}
.contact-button {
  position: fixed;
  right: 12px;
  bottom: 12px;
  transition: all 300ms;
  opacity: 0;
  z-index: 999;
}
@media (max-width: 960px) {
  .contact-button {
    display: flex;
    bottom: 0;
    right: 0;
    width: 100vw;
  }
}
.contact-button .contact {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 100vh;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  width: 240px;
  height: 64px;
  font-size: 1.6rem;
  font-weight: 600;
  transition: all 300ms;
}
@media (max-width: 960px) {
  .contact-button .contact {
    border-radius: unset;
    width: 50vw;
    height: 64px;
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .contact-button .contact {
    font-size: 1.2rem;
    height: 56px;
  }
}
.contact-button .contact:hover {
  opacity: 0.8;
}
.contact-button .contact .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
  background-color: #fff;
  width: 48px;
  height: 48px;
}
@media (max-width: 960px) {
  .contact-button .contact .logo {
    border-radius: 5px;
    height: 100%;
    width: 32px;
    height: 32px;
  }
}
.contact-button .contact p {
  margin-left: 8px;
  color: #fff;
}
.contact-button .contact.reserve {
  margin-bottom: 12px;
  background-color: #AA8C3C;
}
@media (max-width: 960px) {
  .contact-button .contact.reserve {
    margin-bottom: 0;
  }
}
.contact-button .contact.reserve .logo svg {
  width: 26px;
  height: auto;
}
@media (max-width: 960px) {
  .contact-button .contact.reserve .logo svg {
    width: 18px;
  }
}
.contact-button .contact.line {
  background-color: #14a53b;
}
.contact-button .contact.line .logo svg {
  transform: translateY(2px);
  width: 26px;
  height: auto;
}
@media (max-width: 960px) {
  .contact-button .contact.line .logo svg {
    width: 18px;
  }
}

footer {
  position: relative;
}
footer img {
  width: 236px;
}
footer p {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
footer .waxing {
  display: flex;
}
@media (max-width: 960px) {
  footer .waxing {
    flex-direction: column;
  }
}
footer .waxing .lashlift {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 78px 0px;
  background-color: #ffffff;
  border-right: solid 1.5px #d80e77;
}
@media (max-width: 960px) {
  footer .waxing .lashlift {
    flex-direction: row;
    justify-content: space-around;
    padding: 46px 0px;
    border-right: 0;
    border-bottom: solid 1.5px #d80e77;
  }
}
footer .waxing .lashlift img {
  width: 242.5px;
  height: 129.82px;
  margin-bottom: 36px;
  transform: rotate(360deg);
}
@media (max-width: 960px) {
  footer .waxing .lashlift img {
    width: 135px;
    height: auto;
    margin-bottom: 0;
  }
}
footer .waxing .lashlift a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 38px;
  transition: all 0.3s ease;
  background-color: #d80e77;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-decoration: none;
}
@media (max-width: 960px) {
  footer .waxing .lashlift a {
    width: 173px;
    height: 48px;
    font-size: 1.4rem;
  }
}
footer .waxing .lashlift a:hover {
  transition: all 0.3s ease;
  opacity: 0.6;
}
footer .waxing .harley {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 78px 0px;
  background-color: #ffffff;
  border-left: solid 1.5px #d80e77;
}
@media (max-width: 960px) {
  footer .waxing .harley {
    flex-direction: row;
    justify-content: space-around;
    padding: 36px 0px;
    border-top: solid 1.5px #d80e77;
    border-left: 0;
  }
}
footer .waxing .harley img {
  width: 146px;
  height: 130px;
  margin-bottom: 36px;
}
@media (max-width: 960px) {
  footer .waxing .harley img {
    width: 102px;
    height: auto;
    margin-bottom: 0;
  }
}
footer .waxing .harley a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 38px;
  padding: 6px 40px;
  transition: all 0.3s ease;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-decoration: none;
}
@media (max-width: 960px) {
  footer .waxing .harley a {
    width: 172px;
    height: 48px;
    padding: unset;
    font-size: 1.4rem;
  }
}
footer .waxing .harley a:hover {
  transition: all 0.3s ease;
  opacity: 0.6;
}
footer .footer-detail {
  background-color: black;
  text-align: center;
  padding: 24px 0;
  font-size: 1.2rem;
  line-height: 1.3;
  font-family: "Avenir-roman", sans-serif;
  letter-spacing: 0;
}
footer .footer-detail__link {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}
footer .footer-detail__link > a {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1;
  text-decoration: underline;
  transition: all 0.3s ease;
}
footer .footer-detail__link > a:hover {
  transition: all 0.3s ease;
  opacity: 0.6;
}
footer .instagram-link {
  position: absolute;
  bottom: 60px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
  border: solid 2px #AA8C3C;
  width: 30px;
  height: 30px;
}
@media (max-width: 474.98px) {
  footer .instagram-link {
    bottom: 12px;
  }
}
footer .instagram-link svg {
  width: 12px;
  height: auto;
  fill: #AA8C3C;
}

button.button {
  height: 80px;
}

.button {
  background-color: #E60012;
  border: none;
  display: flex;
  position: relative;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.1s;
  width: 100%;
  cursor: pointer;
}
.button:focus {
  outline: none;
}
.button:hover {
  background-color: rgba(230, 0, 18, 0.8);
}
.button p {
  color: white;
  font-size: 24px;
  line-height: 38px;
}
@media (max-width: 960.98px) {
  .button p {
    font-size: 18px;
    text-align: center;
    line-height: 24px;
  }
}
.button img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}
@media (max-width: 434.98px) {
  .button img {
    right: 15px;
  }
}
@media (max-width: 320px) {
  .button img {
    right: 10px;
    width: 15px;
  }
}

.return-btn {
  background-color: #CCCCCC;
}
.return-btn:hover {
  background-color: rgba(204, 204, 204, 0.8);
}
.return-btn img {
  right: auto;
  left: 25px;
  transform: rotate(180deg) translateY(50%);
}
@media (max-width: 434.98px) {
  .return-btn img {
    left: 15px;
  }
}
@media (max-width: 320px) {
  .return-btn img {
    left: 10px;
    width: 15px;
  }
}

.fv-top {
  margin-bottom: 40px;
}
@media (max-width: 520.98px) {
  .fv-top .container {
    width: 100vw;
  }
}
.fv-top::before {
  max-height: calc(100vh + 310px);
}
@media (max-width: 520.98px) {
  .fv-top::before {
    left: 35%;
    width: 400px;
    max-height: calc(100vh + 200px);
  }
}
.fv-top__main__img {
  position: relative;
  display: flex;
  padding-top: 26px;
  margin-bottom: 24px;
  width: 90%;
}
@media (max-width: 520.98px) {
  .fv-top__main__img {
    width: 77%;
    margin-right: 5%;
  }
}
.fv-top__main__img p {
  position: absolute;
  top: 26px;
  right: -8px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  letter-spacing: 0.4rem;
  transform: translateX(100%);
}
.fv-top__main__img--main-img {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}
@media (max-width: 960.98px) {
  .fv-top__main__img--main-img {
    object-fit: cover;
    object-position: right;
  }
}
.fv-top__main__img .msb-logo-sp {
  position: absolute;
  width: 280px;
  height: auto;
  bottom: -50px;
  right: -30px;
  transform: translateY(20%);
}
@media (min-width: 961px) {
  .fv-top__main__img .msb-logo-sp {
    display: none;
  }
}
.fv-top__main__des {
  margin-bottom: 80px;
  position: relative;
}
@media (max-width: 960.98px) {
  .fv-top__main__des {
    margin-top: 123px;
  }
}
.fv-top__main__des img {
  max-width: 100%;
}
.fv-top__main__des p {
  font-weight: bold;
}
.fv-top__main__des p.first {
  font-size: 28px;
  line-height: 1.6;
  letter-spacing: 0.1em;
  text-align: justify;
}
@media (max-width: 520.98px) {
  .fv-top__main__des p.first {
    font-size: 31px;
    line-height: 1.6;
  }
}
@media (max-width: 520.98px) {
  .fv-top__main__des p.first {
    font-size: 22px;
    line-height: 1.6;
  }
}
.fv-top__main__des p:last-child {
  letter-spacing: 2px;
}
.fv-top__main__des .annotation {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #AA8C3C;
  line-height: 1;
  margin-top: 12px;
}
.fv-top__main__des--msb-img {
  position: absolute;
  bottom: 24px;
  right: 0;
  width: 410px;
  height: auto;
}
@media (max-width: 960.98px) {
  .fv-top__main__des--msb-img {
    display: none;
  }
}
.fv-top__main__des .font-97 {
  font-size: 9.7rem;
}
.fv-top__main__des .font-30 {
  font-size: 3rem;
}
.fv-top__main__des .font-36 {
  font-size: 36px;
}
.fv-top__main__des .font-25 {
  font-size: 2.5rem;
}
.fv-top__main__des .font-56 {
  font-size: 56px;
}
.fv-top__news {
  width: 90%;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}
.fv-top__news .news-link {
  display: block;
  border-bottom: dashed 1px #fff;
}
.fv-top__news .news-link:first-child {
  border-top: solid 1px #fff;
}
.fv-top__news .news-link:last-child {
  border-bottom: solid 1px #fff;
}
.fv-top__news #news-wrapper {
  margin-top: 24px;
}
.fv-top__news #news-wrapper .news-detail {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
}
.fv-top__news #news-wrapper .news-detail:hover .news-title {
  color: #AA8C3C;
}
.fv-top__news #news-wrapper .news-detail:hover .news-check-decoration::after {
  width: 0;
}
.fv-top__news #news-wrapper .news-title {
  width: 720px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08em;
  color: #fff;
  margin-right: 16px;
  transition: all 300ms;
}
@media (max-width: 959px) {
  .fv-top__news #news-wrapper .news-title {
    width: 80%;
    margin-right: 0;
  }
}
@media (max-width: 520px) {
  .fv-top__news #news-wrapper .news-title {
    width: 75%;
    font-size: 14px;
  }
}
.fv-top__news #news-wrapper .news-date {
  width: 95px;
  font-family: "Avenir-book", sans-serif;
  font-size: 16px;
  font-weight: 100;
  line-height: 1;
  padding-top: 6px;
  margin-right: 16px;
  color: #fff;
  letter-spacing: 0.08em;
}
@media (max-width: 959px) {
  .fv-top__news #news-wrapper .news-date {
    width: 15%;
    margin-right: 5%;
  }
}
@media (max-width: 520px) {
  .fv-top__news #news-wrapper .news-date {
    width: 20%;
    font-size: 14px;
    margin-right: 5%;
  }
}
.fv-top__news #news-wrapper .news-check-decoration {
  width: 163px;
  font-family: "Avenir-roman", sans-serif;
  padding-top: 6px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.08em;
  position: relative;
  color: #fff;
}
@media (max-width: 959px) {
  .fv-top__news #news-wrapper .news-check-decoration {
    display: none;
  }
}
.fv-top__news #news-wrapper .news-check-decoration::after {
  content: "";
  width: 60%;
  height: 2px;
  display: block;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 300ms;
}
.fv-top__news .view-all-news {
  width: 280px;
  height: 56px;
  margin-top: 40px;
  background-color: #AA8C3C;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  letter-spacing: 0.08em;
  margin-right: auto;
  margin-left: auto;
  transition: all 300ms;
}
.fv-top__news .view-all-news:hover {
  opacity: 0.8;
}
.fv-top__news .no-data {
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.fv-top__second {
  margin-left: calc((100vw - 960px) / 2);
  height: auto;
  padding-top: 120px;
}
.fv-top__second::after {
  display: block;
  clear: both;
  content: "";
}
@media (max-width: 520.98px) {
  .fv-top__second {
    padding-top: 0;
    margin-top: 80px;
  }
}
.fv-top__second.left {
  margin-right: calc((100vw - 960px) / 2);
  margin-left: 0;
}
.fv-top__second.left .fv-top__second--logo {
  top: -147px;
  left: auto;
  right: -184px;
}
@media (max-width: 1124.98px) {
  .fv-top__second.left .fv-top__second--logo {
    right: 0;
  }
}
@media (max-width: 960.98px) {
  .fv-top__second.left .fv-top__second--logo {
    right: -110px;
    top: -100px;
  }
}
@media (max-width: 520.98px) {
  .fv-top__second.left .fv-top__second--logo {
    top: auto;
    right: -48px;
    bottom: 20px;
  }
}
.fv-top__second.left .fv-top__second__main {
  float: left;
}
.fv-top__second.left .fv-top__second--main {
  float: left;
}
.fv-top__second__main {
  position: relative;
  float: right;
  width: auto;
}
@media (max-width: 960.98px) {
  .fv-top__second__main {
    width: 80vw;
    height: auto;
    object-fit: cover;
  }
}
.fv-top__second--logo {
  position: absolute;
  top: -35px;
  left: -105px;
  width: 330px;
  height: auto;
}
@media (max-width: 1124.98px) {
  .fv-top__second--logo {
    left: 0vw;
  }
}
@media (max-width: 960.98px) {
  .fv-top__second--logo {
    width: 240px;
    height: auto;
  }
}
@media (max-width: 520.98px) {
  .fv-top__second--logo {
    width: 132px;
    height: auto;
    top: auto;
    bottom: 20px;
    left: -40px;
  }
}
.fv-top__second--main {
  width: auto;
  height: auto;
  float: right;
}
@media (max-width: 960.98px) {
  .fv-top__second--main {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 520.98px) {
  .section-1 {
    padding-bottom: 450px;
  }
}
.section-1::before {
  top: 297px;
}
@media (max-width: 520.98px) {
  .section-1::before {
    width: 530px;
    left: 80%;
  }
}
.section-1--info {
  font-size: 20px;
  font-weight: 300;
  line-height: 2.4;
  width: 574px;
  letter-spacing: 1px;
  text-align: justify;
  margin-top: 7px;
  font-feature-settings: "palt";
}
@media (max-width: 630px) {
  .section-1--info {
    width: auto;
  }
}
@media (max-width: 520.98px) {
  .section-1--info {
    font-size: 16px;
  }
}
.section-1__features--item:first-child {
  margin-top: 40px;
}
.section-1__features--item:not(:last-child) {
  margin-bottom: 40px;
}
.section-1__features--item p {
  font-size: 2.4rem;
  letter-spacing: 1px;
  line-height: 1.6;
  display: flex;
  align-items: center;
}
@media (max-width: 520.98px) {
  .section-1__features--item p {
    font-size: 1.5rem;
  }
}
.section-1__features--item p span {
  font-size: 72px;
  margin-right: 24px;
  line-height: 9rem;
  display: inline-block;
  position: relative;
}
@media (max-width: 520.98px) {
  .section-1__features--item p span {
    margin-right: 12px;
    font-size: 30px;
    line-height: 41px;
  }
}
.section-1__features--item p span::after {
  width: 37px;
  height: 8px;
  content: "";
  background-color: #fff;
  bottom: 3px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 520.98px) {
  .section-1__features--item p span::after {
    width: 16px;
    height: 2px;
    bottom: 2px;
  }
}
.section-1 .annotation {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  color: #AA8C3C;
  line-height: 1;
  margin-top: 16px;
  font-weight: bold;
}
@media (max-width: 520.98px) {
  .section-1 .annotation {
    font-size: 1.2rem;
    letter-spacing: 0.08em;
  }
}
.section-1 .fv-top__second {
  margin-top: 60px;
  margin-bottom: 40px;
}
@media (max-width: 520.98px) {
  .section-1 .fv-top__second {
    margin-bottom: 20px;
  }
}

.section-2 {
  padding-top: 80px;
  padding-bottom: 60px;
}
@media (max-width: 960.98px) {
  .section-2 {
    padding-top: 40px;
  }
}
@media (max-width: 520.98px) {
  .section-2 {
    margin-top: -450px;
  }
}
.section-2 .section-title {
  margin-bottom: 46px;
}
@media (max-width: 960.98px) {
  .section-2 .section-title {
    margin-bottom: 24px;
  }
}
.section-2 .section-title > p {
  font-size: 42px;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: unset;
}
@media (max-width: 960.98px) {
  .section-2__item {
    background-color: #000;
    width: 100%;
    margin: 0 auto 24px;
  }
}
.section-2__elapsed {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
}
@media (max-width: 960.98px) {
  .section-2__elapsed {
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    margin-bottom: 24px;
  }
}
.section-2__elapsed-logo {
  max-width: 160px;
  width: 100%;
}
@media (max-width: 960.98px) {
  .section-2__elapsed-logo {
    max-width: 61px;
    width: 100%;
  }
}
.section-2__elapsed-logo img {
  width: 100%;
  height: auto;
}
.section-2__elapsed-logo--even {
  order: 2;
}
@media (max-width: 960.98px) {
  .section-2__elapsed-logo--even {
    order: unset;
  }
}
.section-2__elapsed-background {
  position: relative;
  width: 100vw;
  margin-right: calc(50% - 50vw);
  display: flex;
  align-items: center;
  background-color: #000;
  padding: 24px 0 24px 24px;
}
@media (max-width: 960.98px) {
  .section-2__elapsed-background {
    background-color: transparent;
    padding: 0;
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-right: auto;
    align-items: unset;
  }
}
.section-2__elapsed-background--even {
  order: 1;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: 0;
  justify-content: end;
  padding-right: 32px;
  padding-left: 0;
}
@media (max-width: 960.98px) {
  .section-2__elapsed-background--even {
    order: unset;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: unset;
    padding-right: 0;
    padding-left: 0;
  }
}
.section-2__elapsed-background img {
  max-width: 737px;
  width: 100%;
  height: auto;
}
@media (max-width: 960.98px) {
  .section-2__elapsed-background img {
    max-width: 100%;
  }
}
.section-2__bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media (max-width: 520.98px) {
  .section-2__bottom {
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.section-2__bottom__box {
  width: 440px;
  max-width: calc(50% - 20px);
}
@media (max-width: 520.98px) {
  .section-2__bottom__box {
    width: 100%;
    max-width: 100%;
  }
  .section-2__bottom__box:first-child {
    margin-bottom: 24px;
  }
}
.section-2__bottom__box p:first-child {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.14em;
  text-align: justify;
}
@media (max-width: 520.98px) {
  .section-2__bottom__box p:first-child {
    font-size: 20px;
    line-height: 30px;
  }
}
.section-2__bottom__box p:last-child {
  font-size: 16px;
  margin-top: 12px;
  text-align: justify;
  line-height: 1.8;
  letter-spacing: 0.1em;
}
.section-2--movie {
  margin: 0 auto;
  width: 90%;
  max-width: 960px;
  aspect-ratio: 16/9;
}
.section-2--movie video {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.section-3 {
  margin-top: 65px;
  margin-bottom: 80px;
}
@media (max-width: 520.98px) {
  .section-3 {
    margin-top: 20px;
    margin-bottom: 65px;
  }
}
.section-3::before {
  display: none;
}
.section-3__merits {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}
@media (max-width: 520.98px) {
  .section-3__merits {
    flex-direction: column;
    margin-top: 20px;
  }
}
.section-3__merits--item {
  width: 290px;
  max-width: calc(33.3333333333% - 10px);
}
@media (max-width: 520.98px) {
  .section-3__merits--item {
    width: 100%;
    max-width: 100%;
  }
  .section-3__merits--item:not(:last-child) {
    margin-bottom: 35px;
  }
}
.section-3__merits--item img {
  width: auto;
  height: auto;
  max-width: 100%;
}
@media (max-width: 520.98px) {
  .section-3__merits--item img {
    width: 100%;
  }
}
.section-3__merits--item .text-1 {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  margin-top: 7px;
}
.section-3__merits--item .text-caution {
  font-size: 1.2rem;
}

.section-4 {
  margin-bottom: 80px;
}
@media (max-width: 959.98px) {
  .section-4 {
    margin-bottom: 40px;
  }
}

.container {
  position: relative;
}
.container--offline {
  margin-bottom: 56px;
}
@media (max-width: 960.98px) {
  .container--offline {
    margin-bottom: 0;
  }
}
.container--online {
  margin-bottom: 56px;
  padding: 56px 0;
}
.container--recommend {
  margin-top: 40px;
}
.container--recommend::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  background-color: #AA8C3C;
  width: calc((100vw - 100%) / 2);
  height: 100%;
}
.container--online::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  z-index: -1;
  transform: translateX(50%);
  background-color: #000;
  width: 100vw;
  height: 100%;
}

.online-title {
  margin-bottom: 8px;
  color: #AA8C3C;
  font-size: 4rem;
  font-family: "Avenir-roman", sans-serif;
  text-align: center;
}
@media (max-width: 959.98px) {
  .online-title {
    font-size: 3.2rem;
  }
}

.online-sub-title {
  margin-bottom: 16px;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 959.98px) {
  .online-sub-title {
    font-size: 1.8rem;
  }
}

.online-detail {
  margin-bottom: 40px;
  font-size: 1.8rem;
  line-height: 1.6;
}
@media (max-width: 959.98px) {
  .online-detail {
    margin-bottom: 24px;
    font-size: 1.6rem;
  }
}

.recommend {
  position: relative;
  margin-left: auto;
  margin-bottom: 88px;
  padding: 32px 0 40px 24px;
  background-color: #AA8C3C;
  width: 70%;
  min-height: 180px;
}
@media (max-width: 959.98px) {
  .recommend {
    width: 90%;
    margin-bottom: 48px;
  }
}
.recommend::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-98.5%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: #AA8C3C;
  width: 36px;
  height: 100%;
}
.recommend__text-1 {
  margin-bottom: 12px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
}
@media (max-width: 959.98px) {
  .recommend__text-1 {
    font-size: 2rem;
  }
}
.recommend__text-2 {
  margin-bottom: 8px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
}
@media (max-width: 959.98px) {
  .recommend__text-3 {
    font-size: 1.6rem;
    line-height: 1.2;
  }
}
.recommend__campaign {
  position: relative;
  background-color: #AA8C3C;
  margin-bottom: 0;
  padding: 12px 0;
  text-align: center;
}
@media (max-width: 960.98px) {
  .recommend__campaign {
    font-size: 1.8rem;
  }
}
.recommend__campaign::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #AA8C3C;
}
.recommend__campaign::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #AA8C3C;
}

.campaign__content {
  margin-top: 24px;
  padding: 24px;
  background-color: #000;
}
.campaign__content > ul {
  margin-bottom: 16px;
}
.campaign__content > ul > li {
  font-size: 1.4rem;
  list-style: none;
  line-height: 1.4;
}
.campaign__content > ul span {
  border-bottom: solid 2px #AA8C3C;
}
.campaign__content > ol > li {
  font-size: 1.4rem;
  list-style: none;
  line-height: 1.4;
}
.campaign__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 8px;
}
.campaign__text {
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 16px;
}
.campaign__text > span {
  border-bottom: solid 2px #AA8C3C;
}
.campaign__note {
  font-size: 1.4rem;
  line-height: 1.4;
}

.course__news {
  letter-spacing: 0.1em;
  margin-top: 40px;
  padding: 16px;
  border: solid 1px #AA8C3C;
}
@media (max-width: 960.98px) {
  .course__news {
    margin-top: 24px;
  }
}
.course__news-title {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
@media (max-width: 960.98px) {
  .course__news-title {
    margin-top: 14px;
  }
}
.course__news-title > span {
  display: inline-block;
  line-height: 1.2;
  border-bottom: solid 1px #AA8C3C;
}
.course__news-text {
  font-size: 1.6rem;
  line-height: 1.6;
}
@media (max-width: 960.98px) {
  .course__news-text {
    font-size: 1.4rem;
    line-height: 1.9;
  }
}
.course__news-text > span {
  display: inline-block;
  font-weight: bold;
  line-height: 1.2;
  border-bottom: solid 1px #AA8C3C;
}
@media (max-width: 960.98px) {
  .course__news-text > span {
    display: inline;
  }
}
.course__pickup {
  display: flex;
  gap: 3%;
}
@media (max-width: 960.98px) {
  .course__pickup {
    flex-direction: column;
    gap: unset;
  }
}
.course__area {
  width: 100%;
  margin-top: 24px;
  background-color: #000;
  padding: 24px;
}
.course__area--small {
  width: 48.5%;
}
@media (max-width: 960.98px) {
  .course__area--small {
    width: 100%;
  }
}
.course__head {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}
.course__title {
  color: #aa8c3c;
  font-size: 1.8rem;
  font-family: "Avenir-roman", sans-serif;
  letter-spacing: 0.2em;
  line-height: 1.4;
  letter-spacing: 0.1em;
}
.course__badge {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  color: white;
  margin-bottom: 16px;
}
.course__badge span {
  background-color: #aa8c3c;
  line-height: 1.4;
  padding: 4px 8px;
}
@media (max-width: 520.98px) {
  .course__badge span {
    padding: 1.5px 4px 2px;
  }
}
.course__name {
  font-size: 2.4rem;
  line-height: 1.5;
  margin-bottom: 8px;
}
.course__item {
  display: flex;
  gap: 1.7%;
}
@media (max-width: 959.98px) {
  .course__item {
    flex-direction: column;
  }
}
.course__avatar {
  width: 33%;
}
@media (max-width: 959.98px) {
  .course__avatar {
    width: 100%;
  }
}
.course__avatar--pickup {
  width: 100%;
}
.course__contents {
  width: 65.5%;
}
@media (max-width: 959.98px) {
  .course__contents {
    width: 100%;
  }
}
.course__contents--pickup {
  width: 100%;
}
.course__image {
  margin-bottom: 8px;
}
@media (max-width: 959.98px) {
  .course__image {
    margin-bottom: 7px;
  }
}
.course__image img {
  width: 100%;
  height: auto;
}
.course__note {
  font-size: 1.2rem;
  letter-spacing: 0.01em;
  line-height: 1.6;
  padding-left: 2em;
}
.course__note--margin-bottom {
  margin-bottom: 0;
}
.course__text {
  font-size: 1.6rem;
  line-height: 1.3;
  margin-bottom: 8px;
}
.course__menu {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6;
}
@media (max-width: 520.98px) {
  .course__menu {
    line-height: 1.5;
  }
}
.course__menu > span {
  padding-left: 1em;
  text-indent: -1em;
}
.course__border {
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 16px 0;
}
.course__dots-border {
  height: 1px;
  margin-bottom: 16px;
}
@media (max-width: 960.98px) {
  .course__dots-border {
    margin-bottom: 0px;
  }
}
.course__field {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.course__price-area > p {
  font-size: 1.6rem;
  letter-spacing: 0.08em;
  margin-bottom: 26px;
  font-weight: bold;
}
.course__reality {
  width: 100%;
  margin-bottom: 22px;
  border-bottom: solid 1px #AA8C3C;
}
@media (max-width: 520.98px) {
  .course__reality {
    width: 100%;
    padding: 0;
    background: none;
    margin-bottom: 12px;
  }
}
.course__online {
  width: 100%;
  border-bottom: solid 1px #8b8b8b;
}
@media (max-width: 520.98px) {
  .course__online {
    width: 100%;
    padding: 0;
    background: none;
  }
}
.course__price {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 10px;
}
@media (max-width: 520.98px) {
  .course__price {
    align-items: center;
    justify-content: space-between;
  }
}
.course__training {
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
@media (max-width: 520.98px) {
  .course__training {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.course__fee {
  font-family: "Avenir", sans-serif;
  font-size: 3.2rem;
  letter-spacing: 0.08em;
}
.course__fee--online {
  display: flex;
  align-items: baseline;
  gap: 0.4rem;
}
.course__annotation {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #aa8c3c;
  letter-spacing: 0;
  margin-top: 16px;
}
.course__heading {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.06em;
  line-height: 1.5;
  text-align: center;
  margin: 40px 0 24px;
}
.course__subtext {
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.2;
}
@media (max-width: 520.98px) {
  .course__subtext {
    text-align: unset;
    line-height: 1.7;
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 12px 0 16px;
}
.slick-dots button {
  display: none;
}
.slick-dots li {
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 100vh;
  background-color: #fff;
  list-style: none;
}
.slick-dots li.slick-active {
  background-color: #AA8C3C;
}

.slick-slide {
  margin: 0 8px;
}

.section-5 {
  margin-top: 120px;
  min-height: 610px;
  position: relative;
}
@media (max-width: 520.98px) {
  .section-5 {
    margin-top: -300px;
    padding-top: 70px;
  }
}
@media (max-width: 960.98px) {
  .section-5 {
    min-height: 800px;
    margin-bottom: 60px;
  }
}
.section-5::before {
  display: none;
}
.section-5__main {
  width: calc(100vw - (100vw - 960px) / 2 - 60px);
  height: auto;
  margin-top: 50px;
  padding-top: 30px;
  padding-left: 116px;
  padding-bottom: 110px;
  background-color: #000;
  float: right;
  clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
  z-index: 2;
  position: relative;
}
@media (max-width: 960.98px) {
  .section-5__main {
    width: 95%;
    padding-right: 5%;
    padding-top: 100px;
    padding-left: 80px;
    padding-bottom: 40px;
    margin-top: -60px;
  }
}
.section-5__main p.trainer-name {
  font-size: 18px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.section-5__main p.trainer-detail {
  width: 100%;
  max-width: 290px;
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
}
@media (max-width: 960.98px) {
  .section-5__main p.trainer-detail {
    max-width: 350px;
  }
}
.section-5__py {
  width: 100%;
  background-color: #AA8C3C;
  padding-left: 65px;
  padding-top: 25px;
  padding-bottom: 33px;
  padding-right: 40px;
  clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
}
@media (max-width: 520.98px) {
  .section-5__py {
    width: 95%;
    align-self: flex-end;
    padding-top: 60px;
    padding-right: 10%;
  }
}
.section-5__py p {
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  text-align: justify;
}
@media (max-width: 520.98px) {
  .section-5__py p {
    width: 100%;
    max-width: 100%;
  }
}
.section-5__py p:last-child {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.section-5__bottom {
  position: relative;
}
.section-5__bottom::after {
  display: block;
  clear: both;
  content: "";
}
.section-5__bottom .trainer-image {
  width: 282px;
  height: 365px;
  position: relative;
  z-index: 999;
  margin-top: 50px;
  margin-left: 15%;
}
.section-5__bottom .trainer-image:before {
  content: "";
  width: 200px;
  height: 200px;
  background-color: #AA8C3C;
  display: block;
  position: absolute;
  z-index: -1;
  top: -30px;
  left: -25px;
}
.section-5__bottom .trainer-image:after {
  content: url("../assets/images/trainer-name.webp");
  display: block;
  position: absolute;
  bottom: -50px;
  right: -10%;
}
@media (min-width: 961px) {
  .section-5__bottom .trainer-image {
    display: none;
  }
}
.section-5__bottom .trainer-image img {
  width: 100%;
}
.section-5__bottom .trainer-img-wrapper {
  width: 380px;
  height: 490px;
  position: absolute;
  top: 25px;
  right: calc((100vw - 960px) / 2);
  z-index: 3;
}
@media (max-width: 960.98px) {
  .section-5__bottom .trainer-img-wrapper {
    display: none;
  }
}
.section-5__bottom .trainer-img-wrapper:before {
  content: "";
  width: 200px;
  height: 200px;
  background-color: #AA8C3C;
  display: block;
  position: absolute;
  top: -25px;
  left: -20px;
  z-index: -1;
}
.section-5__bottom .trainer-img-wrapper:after {
  content: url("../assets/images/trainer-name.webp");
  position: absolute;
  bottom: 150px;
  left: -165px;
}
.section-5__bottom .trainer-img-wrapper img {
  width: 100%;
}
.section-5__bottom div.decoration {
  width: calc(100vw - (100vw - 960px) / 2 - 60px);
  height: 311px;
  background-color: #AA8C3C;
  float: right;
  clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
  z-index: 1;
  position: absolute;
  right: -111px;
  top: 100px;
}
@media (max-width: 960.98px) {
  .section-5__bottom div.decoration {
    width: 95%;
    top: 320px;
    left: 90px;
  }
}
@media (max-width: 520.98px) {
  .section-5__bottom {
    width: 100vw;
  }
}

.contact-info {
  width: 90%;
  max-width: 960px;
  margin-top: 40px;
  margin-bottom: 120px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #000;
}
@media (max-width: 800px) {
  .contact-info {
    padding: 40px 24px;
    margin-bottom: 80px;
  }
}
.contact-info .description {
  color: #fff;
  text-align: center;
  letter-spacing: 0.12em;
  line-height: 1.6;
}
.contact-info .info-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
}
@media (max-width: 800px) {
  .contact-info .info-wrapper {
    flex-direction: column;
  }
}
.contact-info .info-detail {
  width: 50%;
  padding: 0 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 800px) {
  .contact-info .info-detail {
    padding: 0;
    width: 100%;
  }
  .contact-info .info-detail:first-child {
    margin-bottom: 20px;
  }
}
.contact-info .info-detail .caption {
  margin-bottom: 8px;
  font-size: 18px;
  color: #AA8C3C;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.contact-info .info-detail .img-wrapper {
  display: flex;
  margin-bottom: 4px;
  width: 100%;
  cursor: pointer;
}
.contact-info .info-detail .img-wrapper img {
  width: 100%;
  height: auto;
}
.contact-info .info-detail a {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
  pointer-events: none;
}
@media (max-width: 800px) {
  .contact-info .info-detail a {
    font-size: 15px;
  }
}
.contact-info .info-detail a.tel {
  font-size: 26px;
  margin-bottom: 4px;
}
@media (max-width: 800px) {
  .contact-info .info-detail a.tel {
    pointer-events: all;
  }
}
.contact-info .info-detail a img {
  width: 100%;
  height: auto;
}
.contact-info .info-detail .contact-caution {
  font-size: 12px;
  line-height: 1;
}
.contact-info .separator {
  width: 3px;
  height: 124px;
  background-color: #fff;
}
@media (max-width: 800px) {
  .contact-info .separator {
    display: none;
  }
}

.case-study {
  width: 90%;
  max-width: 960px;
  height: auto;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
  overflow-x: hidden;
}
@media (max-width: 960.98px) {
  .case-study {
    margin-bottom: 40px;
  }
}

.slider-wrapper {
  margin-top: 40px;
}

.slider {
  width: 100%;
  display: flex;
}
@media (max-width: 699px) {
  .slider {
    flex-direction: column;
  }
}
.slider__img {
  width: 43%;
  height: fit-content;
  max-width: 400px;
  line-height: 1;
}
@media (max-width: 1065px) {
  .slider__img {
    width: 45%;
    max-width: unset;
  }
}
@media (max-width: 960px) {
  .slider__img {
    width: 60%;
  }
}
@media (max-width: 699px) {
  .slider__img {
    width: 100%;
  }
}
.slider__img .img-wrapper {
  width: 100%;
  height: auto;
  position: relative;
}
.slider__img .img-wrapper img {
  width: 100%;
}
.slider__img .img-wrapper::after {
  content: "";
  width: 180px;
  height: 180px;
  background-color: #000000;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: translateX(50%) translateY(50%);
}
.slider__img .controller {
  margin-top: 16px;
  line-height: 0.8;
  display: flex;
}
@media (max-width: 520.98px) {
  .slider__img .controller {
    margin-top: 8px;
  }
}
.slider__img .controller .btn {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 0;
  border: none;
  background-color: #fff;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 699px) {
  .slider__img .controller .btn {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 8px;
  }
}
.slider__img .controller .btn.active {
  background-color: #AA8C3C;
  color: #fff;
}
.slider__description {
  width: 57.5%;
  max-width: 540px;
  height: auto;
  margin-left: auto;
  padding-top: 40px;
}
@media (max-width: 1065px) {
  .slider__description {
    width: 100%;
    padding-left: 20px;
    max-width: unset;
    margin-left: unset;
  }
}
@media (max-width: 960px) {
  .slider__description {
    width: 55%;
    padding-top: 20px;
  }
}
@media (max-width: 699px) {
  .slider__description {
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
  }
}
.slider__description .text1 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.08em;
  position: relative;
  z-index: 1;
  line-height: 1.4;
  word-break: break-all;
}
@media (max-width: 1065px) {
  .slider__description .text1 {
    font-size: 20px;
  }
}
@media (max-width: 699px) {
  .slider__description .text1 {
    font-size: 24px;
  }
}
.slider__description .text1 .eng {
  font-family: "Avenir", sans-serif;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.1em;
  margin-right: 8px;
}
@media (max-width: 1065px) {
  .slider__description .text1 .eng {
    font-size: 16px;
  }
}
@media (max-width: 699px) {
  .slider__description .text1 .eng {
    font-size: 2.4rem;
  }
}
.slider__description .trapezoid {
  width: calc(100% + 20px);
  height: 32px;
  position: relative;
  z-index: 0;
  margin-top: -14px;
  margin-left: -20px;
  margin-bottom: 20px;
  background-color: #AA8C3C;
  clip-path: polygon(0 0, 98% 0, 100% 100%, 0 100%);
}
@media (max-width: 1065px) {
  .slider__description .trapezoid {
    margin-top: -10px;
    height: 24px;
  }
}
@media (max-width: 699px) {
  .slider__description .trapezoid {
    width: 105%;
    height: 16px;
    margin-left: -6%;
  }
}
.slider__description .text2 {
  font-size: 22px;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 16px;
}
@media (max-width: 699px) {
  .slider__description .text2 {
    margin-bottom: 8px;
    font-size: 20px;
  }
}
.slider__description .text3 {
  font-size: 16px;
  line-height: 1.8;
  text-align: justify;
}

.reserve-container {
  width: 90%;
  max-width: 960px;
  margin-top: 40px;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
  padding: 24px;
  background-color: #fff;
  position: relative;
}
@media (max-width: 960.98px) {
  .reserve-container {
    padding: 24px;
  }
}
.reserve-container .title-wrapper {
  width: 100%;
  height: auto;
  position: relative;
}
.reserve-container .title-wrapper::after {
  content: "";
  width: calc(100% - 60px);
  height: 1px;
  background-color: #000000;
  display: block;
  position: absolute;
  top: 50%;
  left: 60px;
  z-index: 1;
  transform: translateY(-50%);
}
.reserve-container .title {
  color: #000000;
  background-color: #fff;
  font-size: 24px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600;
  padding-right: 16px;
  line-height: 1;
  display: inline-block;
  position: relative;
  z-index: 2;
}
.reserve-container .description {
  color: #000000;
  line-height: 1.6;
  letter-spacing: 0;
  margin-top: 16px;
  text-align: justify;
  font-weight: 500;
}
@media (max-width: 520.98px) {
  .reserve-container .description {
    margin-bottom: 20px;
    line-height: 1.8;
    font-size: 1.8rem;
  }
}

.reserve-course__title {
  color: black;
  font-size: 2.8rem;
  font-weight: bold;
  padding-left: 8px;
  border-left: solid #aa8c3c 6px;
  margin: 24px 0 16px;
}
@media (max-width: 520.98px) {
  .reserve-course__title {
    font-size: 2rem;
    line-height: 1.35;
  }
}
.reserve-course__link {
  color: white;
  transition: all 300ms;
}
.reserve-course__link:hover {
  opacity: 0.8;
  transition: all 300ms;
}
.reserve-course__link--color {
  color: #aa8c3c;
}
.reserve-course__button {
  background-color: #aa8c3c;
  border: solid 2px #aa8c3c;
  border-radius: 3px;
  margin-bottom: 8px;
  text-align: center;
  padding: 13px 0;
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 520.98px) {
  .reserve-course__button {
    padding: 10px 0;
  }
}
.reserve-course__button--status {
  background-color: white;
  margin-bottom: 0;
}

.contact-info {
  width: 90%;
  max-width: 960px;
  margin-top: 40px;
  margin-bottom: 120px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #000;
}
@media (max-width: 800px) {
  .contact-info {
    padding: 40px 24px;
    margin-bottom: 80px;
  }
}
.contact-info .description {
  color: #fff;
  text-align: center;
  letter-spacing: 0.12em;
  line-height: 1.6;
}
.contact-info .info-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
}
@media (max-width: 800px) {
  .contact-info .info-wrapper {
    flex-direction: column;
  }
}
.contact-info .info-detail {
  width: 50%;
  padding: 0 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 800px) {
  .contact-info .info-detail {
    padding: 0;
    width: 100%;
  }
  .contact-info .info-detail:first-child {
    margin-bottom: 20px;
  }
}
.contact-info .info-detail .caption {
  margin-bottom: 8px;
  font-size: 18px;
  color: #AA8C3C;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.contact-info .info-detail .img-wrapper {
  display: flex;
  margin-bottom: 4px;
  width: 100%;
  cursor: pointer;
}
.contact-info .info-detail .img-wrapper img {
  width: 100%;
  height: auto;
}
.contact-info .info-detail a {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
  pointer-events: none;
}
@media (max-width: 800px) {
  .contact-info .info-detail a {
    font-size: 15px;
  }
}
.contact-info .info-detail a.tel {
  font-size: 26px;
  margin-bottom: 4px;
}
@media (max-width: 800px) {
  .contact-info .info-detail a.tel {
    pointer-events: all;
  }
}
.contact-info .info-detail a img {
  width: 100%;
  height: auto;
}
.contact-info .info-detail .contact-caution {
  font-size: 12px;
  line-height: 1;
}
.contact-info .separator {
  width: 3px;
  height: 124px;
  background-color: #fff;
}
@media (max-width: 800px) {
  .contact-info .separator {
    display: none;
  }
}

.section-certificate {
  width: 90%;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 120px;
}
@media (max-width: 520.98px) {
  .section-certificate {
    margin-bottom: 320px;
  }
}
.section-certificate .text1 {
  font-size: 18px;
  margin-top: 24px;
  letter-spacing: 0.1em;
  line-height: 1.6;
  margin-bottom: 24px;
}
@media (max-width: 520.98px) {
  .section-certificate .text1 {
    font-size: 16px;
  }
}
.section-certificate .main-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 799px) {
  .section-certificate .main-content {
    flex-direction: column;
  }
}
.section-certificate .certificate-wrapper {
  width: 32%;
  max-width: 460px;
  height: auto;
}
@media (max-width: 799px) {
  .section-certificate .certificate-wrapper {
    width: 100%;
    max-width: unset;
    margin-bottom: 24px;
  }
  .section-certificate .certificate-wrapper:last-child {
    margin-bottom: 0;
  }
}
.section-certificate .certificate-wrapper .description {
  width: 100%;
  background-color: #000000;
  border-top: solid 8px #AA8C3C;
  padding: 8px 0 12px;
}
@media (max-width: 520.98px) {
  .section-certificate .certificate-wrapper .description {
    padding: 12px 3%;
  }
}
.section-certificate .certificate-wrapper .description .text2 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.12em;
  text-align: center;
  line-height: 1;
  margin-bottom: 12px;
}
.section-certificate .certificate-wrapper .description .text3 {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.12em;
  text-align: center;
  line-height: 1;
  margin-bottom: 8px;
}
.section-certificate .certificate-wrapper .description .text4 {
  font-size: 16px;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 0.1em;
}
.section-certificate .certificate {
  background-color: #8b8b8b;
}
.section-certificate .img-wrapper {
  width: 50%;
  line-height: 1;
  margin: 0 auto;
}
.section-certificate .img-wrapper img {
  width: 100%;
  height: auto;
}

.nav-banner {
  margin-bottom: 120px;
}
@media (max-width: 800px) {
  .nav-banner {
    margin-bottom: 80px;
  }
}
.nav-banner__image {
  width: 480px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 800px) {
  .nav-banner__image {
    width: 100%;
  }
}
.nav-banner__image img {
  width: 100%;
  height: auto;
}

@keyframes campaign-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}