.contact-button {
  position: fixed;
  right: 12px;
  bottom: 12px;
  transition: all 300ms;
  opacity: 0;
  z-index: 999;

  @include response(960) {
    display: flex;
    bottom: 0;
    right: 0;
    width: 100vw;
  }

  .contact {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 100vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    width: 240px;
    height: 64px;
    font-size: 1.6rem;
    font-weight: 600;
    transition: all 300ms;

    @include response(960) {
      border-radius: unset;
      width: 50vw;
      height: 64px;
      font-size: 1.4rem;
    }

    @include response(480) {
      font-size: 1.2rem;
      height: 56px;
    }

    &:hover {
      opacity: 0.8;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100vh;
      background-color: $white;
      width: 48px;
      height: 48px;

      @include response(960) {
        border-radius: 5px;
        height: 100%;
        width: 32px;
        height: 32px;
      }
    }

    p {
      margin-left: 8px;
      color: $white;
    }

    &.reserve {
      margin-bottom: 12px;
      background-color: $color-yellow-dark;

      @include response(960) {
        margin-bottom: 0;
      }

      .logo svg {
        width: 26px;
        height: auto;

        @include response(960) {
          width: 18px;
        }
      }
    }

    &.line {
      background-color: #14a53b;

      .logo svg {
        transform: translateY(2px);
        width: 26px;
        height: auto;

        @include response(960) {
          width: 18px;
        }
      }
    }
  }
}

footer {
  position: relative;

  img {
    width: 236px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }

  .waxing {
    display: flex;

    @include response(960) {
      flex-direction: column;
    }

    .lashlift {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      padding: 78px 0px;
      background-color: #ffffff;
      border-right: solid 1.5px #d80e77;

      @include response(960) {
        flex-direction: row;
        justify-content: space-around;
        padding: 46px 0px;
        border-right: 0;
        border-bottom: solid 1.5px #d80e77;
      }

      img {
        width: 242.5px;
        height: 129.82px;
        margin-bottom: 36px;
        transform: rotate(360deg);

        @include response(960) {
          width: 135px;
          height: auto;
          margin-bottom: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;
        height: 38px;
        transition: all 0.3s ease;
        background-color: #d80e77;
        color: #ffffff;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.1em;
        line-height: calc(27px / 18px);
        text-decoration: none;

        @include response(960) {
          width: 173px;
          height: 48px;
          font-size: 1.4rem;
        }

        &:hover {
          transition: all 0.3s ease;
          opacity: 0.6;
        }
      }
    }

    .harley {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      padding: 78px 0px;
      background-color: #ffffff;
      border-left: solid 1.5px #d80e77;

      @include response(960) {
        flex-direction: row;
        justify-content: space-around;
        padding: 36px 0px;
        border-top: solid 1.5px #d80e77;
        border-left: 0;
      }

      img {
        width: 146px;
        height: 130px;
        margin-bottom: 36px;

        @include response(960) {
          width: 102px;
          height: auto;
          margin-bottom: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;
        height: 38px;
        padding: 6px 40px;
        transition: all 0.3s ease;
        background-color: #000000;
        color: #ffffff;
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        line-height: calc(27px / 18px);
        text-decoration: none;

        @include response(960) {
          width: 172px;
          height: 48px;
          padding: unset;
          font-size: 1.4rem;
        }

        &:hover {
          transition: all 0.3s ease;
          opacity: 0.6;
        }
      }
    }
  }

  .footer-detail {
    background-color: black;
    text-align: center;
    padding: 24px 0;
    font-size: 1.2rem;
    line-height: 1.3;
    font-family: "Avenir-roman", sans-serif;
    letter-spacing: 0;

    &__link {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin-bottom: 16px;

      & > a {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 1;
        text-decoration: underline;
        transition: all 0.3s ease;

        &:hover {
          transition: all 0.3s ease;
          opacity: 0.6;
        }
      }
    }
  }

  .instagram-link {
    position: absolute;
    bottom: 60px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    border: solid 2px $color-yellow-dark;
    width: 30px;
    height: 30px;

    @include media-breakpoint-down(sm) {
      bottom: 12px;
    }

    svg {
      width: 12px;
      height: auto;
      fill: $color-yellow-dark;
    }
  }
}
