.section-2 {
  padding-top: 80px;
  padding-bottom: 60px;

  @include media-breakpoint-down(xl) {
    padding-top: 40px;
  }

  @include media-breakpoint-down(md) {
    margin-top: -450px;
  }

  .section-title {
    margin-bottom: 46px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 24px;
    }

    & > p {
      font-size: 42px;
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: unset;
    }
  }

  &__item {
    @include media-breakpoint-down(xl) {
      background-color: $black;
      width: 100%;
      margin: 0 auto 24px;
    }
  }

  &__elapsed {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 40px;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      // width: 90%;
      // margin: 0 auto;
      gap: 16px;
      padding: 24px;
      margin-bottom: 24px;
    }

    &-logo {
      max-width: 160px;
      width: 100%;

      @include media-breakpoint-down(xl) {
        max-width: 61px;
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }

      &--even {
        order: 2;

        @include media-breakpoint-down(xl) {
          order: unset;
        }
      }
    }

    &-background {
      position: relative;
      width: 100vw;
      margin-right: calc(50% - 50vw);
      display: flex;
      align-items: center;
      background-color: $black;
      padding: 24px 0 24px 24px;

      @include media-breakpoint-down(xl) {
        background-color: transparent;
        padding: 0;
        width: 100%;
        height: auto;
        flex-direction: column;
        margin-right: auto;
        align-items: unset;
      }

      &--even {
        order: 1;
        width: 100vw;
        margin-left: calc(50% - 50vw);
        margin-right: 0;
        justify-content: end;
        padding-right: 32px;
        padding-left: 0;

        @include media-breakpoint-down(xl) {
          order: unset;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          justify-content: unset;
          padding-right: 0;
          padding-left: 0;
        }
      }

      img {
        max-width: 737px;
        width: 100%;
        height: auto;

        @include media-breakpoint-down(xl) {
          max-width: 100%;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom:40px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 40px;
    }

    &__box {
      width: 440px;
      max-width: calc(100% / 2 - 20px);

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100%;

        &:first-child {
          margin-bottom: 24px;
        }
      }

      p {
        &:first-child {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: 0.14em;
          text-align: justify;

          @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 30px;
          }
        }

        &:last-child {
          font-size: 16px;
          margin-top: 12px;
          text-align: justify;
          line-height: 1.8;
          letter-spacing: 0.1em;
        }
      }
    }
  }

  &--movie {
    margin: 0 auto;
    width: 90%;
    max-width: 960px;
    aspect-ratio: 16 / 9;

    video {
      width: 100%;
      height: 100%;
      background-color: $black;
    }
  }
}
